import { motion } from "framer-motion";
import Backdrop from "./backdrop";
import Button from "./button";

import { useEffect, useState } from "react";

export function cookieConsentGiven() {
  if (!localStorage.getItem('cookie_consent')) {
    return false;
  }
  return localStorage.getItem('cookie_consent');
}

export default function Cookies() {

    const [consentGiven, setConsentGiven] = useState(false);

    useEffect(() => {
        setConsentGiven(cookieConsentGiven());
    }, []);

    const handleAcceptCookies = () => {
        localStorage.setItem('cookie_consent', true);
        setConsentGiven(true);
    };

    return (
        <div>
            {!consentGiven &&
                <Backdrop>
                    <motion.div className="sct fxc aic cks"
                        initial={{
                            y: 200,
                            opacity: 0
                        }}
                        animate={{
                            y: 0,
                            opacity: 1
                        }}
                        transition={{
                            type: "linear"
                        }}
                    >
                        <div className="c fxc pd3224 gp4">
                            <div className="fnts18 fntw700 fcb">Cookies Policy</div>
                            <p className="fnts14 fntw400 fcdg">
                            We only use cookies that are essential for the operation of our website. These cookies are necessary to enable core functionalities such as security, network management, and accessibility. Without these cookies, the website cannot function properly. By continuing to use our website, you consent to the placement of these necessary cookies on your device. Please note that if you do not agree to the use of these cookies, we regret that you will not be able to access or use our services. You can get more information about metioned third party services in our Privacy Policy.
                            </p>
                            <div className="cksbtn">
                                <Button text={"ACCEPT & CONTINUE"} size={"btns"} primary={"blb"} secondary={"blb" + "ter"} color={"fcw"} onClick={() => handleAcceptCookies()} />
                            </div>
                        </div>
                    </motion.div>
                </Backdrop>
            }
        </div>
    );
}