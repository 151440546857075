import { useEffect } from "react";
import { useState } from "react";

import { auth } from "../../lib/firebase";

import TextField from "../../components/textfield";
import Button from "../../components/button";
import { confirmPasswordReset } from "firebase/auth"
import Header from "../../components/header";

import { useNavigate } from "react-router-dom";

export default function ResetPassword() {

    const queryParameters = new URLSearchParams(window.location.search);
    const code = queryParameters.get("oobCode");

    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (!code) {
            navigate("/");
        }
        document.title = 'Reset Password - Cognido';
      }, []);

      async function handleReset() {
        setError("")
        confirmPasswordReset(auth, code, password)
            .then(function() {
                setSuccess(true);
            })
            .catch(function(err) {
                setError("An error occurred.")
            })
            
    }

    return (
        <div>
            <Header background={"bdb"} btnShown={false} sticky={true} />
            <div className="sct fxc aic">
                <div className="pasres fxc pd6424 gp24">
                    <div className="fxc gp16">
                        <div className="fnts24 fntw700 fcb">Reset Password</div>
                        <p className="fnts18 fntw400 fclg">
                            Choose your new Password.
                        </p>
                    </div>
                    {success ? <p>Password reset successful.</p> :
                            <div className="fxc gp24">
                                <TextField title="New Password" text={password} setText={setPassword} secure={true} />
                                <div>
                                    <Button text={"RESET PASSWORD"} size={"btn"} primary={"blb"} secondary={"blbter"} color={"fcw"} onClick={handleReset} />
                                </div>
                                {error &&
                                    <div>{error}</div>
                                }
                            </div>
                        }
                </div>
            </div>
        </div>
    )
}