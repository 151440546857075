import logo_w from "../assets/branding/logo.white.png"
import logotext_w from "../assets/branding/logotext.white.png"
import waves from "../assets/assets/footer.waves.png"
import { Link } from "react-router";

export default function Footer(props) {
    return (
        <div className="fxc">
            <div className="sct ftwav">
                <img src={waves} />
            </div>
            <div className="sct bdb fxc aic">
                <div className="c pd6424">
                    <div className="fxc gp16 ftl">
                        <div className="fclb fnts18 fntw700">Apps</div>
                        <a href="https://apps.apple.com/de/app/cognido-minispiel-duelle/id6670284138">
                            <div className="fcw fnts16 fntw500">Cognido for iOS</div>
                        </a>
                        <div className="fcw fnts16 fntw500">Cognido for Android</div>
                    </div>
                    <div className="fxc gp16 ftl">
                        <div className="fclb fnts18 fntw700">Products</div>
                        <div className="fcw fnts16 fntw500">Cognido</div>
                        <a href="#plus">
                        <div className="fcw fnts16 fntw500">Cognido Plus</div>
                        </a>
                    </div>
                    <div className="fxc gp16 ftl">
                        <div className="fclb fnts18 fntw700">Help & Support</div>
                        <a href="#faq">
                        <div className="fcw fnts16 fntw500">FAQs</div>
                        </a>
                    </div>
                    <div className="fxc gp16 ftl">
                        <div className="fclb fnts18 fntw700">Privacy & Terms</div>
                        <a href="/legal/privacy">
                        <div className="fcw fnts16 fntw500">Privacy</div>
                        </a>
                        <a href="/legal/terms">
                        <div className="fcw fnts16 fntw500">Terms</div>
                        </a>
                    </div>
                    <div className="fxc gp16 ftl">
                        <div className="fclb fnts18 fntw700">Social</div>
                        <div className="fcw fnts16 fntw500">Blog</div>
                        <div className="fcw fnts16 fntw500">Instagram</div>
                        <div className="fcw fnts16 fntw500">Twitter</div>
                        <div className="fcw fnts16 fntw500">YouTube</div>
                        <div className="fcw fnts16 fntw500">TikTok</div>
                    </div>
                </div>
            </div>
        </div>
    );
}