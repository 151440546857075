import { useEffect, useState, useRef } from "react";
import Footer from "../../components/footer"
import Header from "../../components/header";
import Button from "../../components/button";
import Nido from "../../nido/nido";

import coin from "../../assets/assets/coin.png"
import chat from "../../assets/assets/chatbubble.png"

import training from "../../assets/assets/training.png"
import none from "../../assets/assets/none.png"
import bot from "../../assets/assets/bot.png"
import bubble from "../../assets/assets/bubble.png"

import plus from "../../assets/assets/plus.stamp.png"

import chat1 from "../../assets/assets/chatbubble1.png"
import chat2 from "../../assets/assets/chatbubble2.png"
import chat3 from "../../assets/assets/chatbubble3.png"

import appstore from "../../assets/assets/appstorebtn.png"

import arrowDown from "../../assets/assets/arrow.down.png"

import diamond from "../../assets/assets/diamond.png"
import gold from "../../assets/assets/gold.png"
import silver from "../../assets/assets/silver.png"
import globe from "../../assets/assets/globe.png"
import duel from "../../assets/assets/duel.png"
import pluswaves from "../../assets/assets/pluswaves.png"

import { motion, AnimatePresence } from "framer-motion";
import Backdrop from "../../components/backdrop";
import Cookies from "../../components/cookies";

const useIntersection = (element, rootMargin) => {
    const [isVisible, setState] = useState(false);
  
    useEffect(() => {
      const current = element?.current;
      const observer = new IntersectionObserver(
        ([entry]) => {
          setState(entry.isIntersecting);
        },
        { rootMargin }
      );
      current && observer?.observe(current);
  
      return () => current && observer.unobserve(current);
    }, []);
  
    return isVisible;
  };

export default function Cognido() {

    const cog1Ref = useRef(null);
    const isCog1Visible = useIntersection(cog1Ref, "0px");
    const cog3Ref = useRef(null);
    const isCog3Visible = useIntersection(cog3Ref, "0px");
    const cog4Ref = useRef(null);
    const isCog4Visible = useIntersection(cog4Ref, "0px");
    const cog6Ref = useRef(null);
    const isCog6Visible = useIntersection(cog6Ref, "0px");
    const footerRef = useRef(null);
    const isFooterVisible = useIntersection(footerRef, "0px");
    const btnRef = useRef(null);
    const isBtnVisible = useIntersection(btnRef, "0px");

    const [mouse1, setMouse1] = useState([0, 0])
    const [mouse2, setMouse2] = useState([0, 0])

    const [flippedQ1, setFlippedQ1] = useState(false)
    const [flippedQ2, setFlippedQ2] = useState(false)
    const [flippedQ3, setFlippedQ3] = useState(false)
    const [flippedQ4, setFlippedQ4] = useState(false)
    const [flippedQ5, setFlippedQ5] = useState(false)
    const [flippedQ6, setFlippedQ6] = useState(false)

    const [start, setStart] = useState(null);
    const [target, setTarget] = useState(0);
    const [chatCount, setChatCount] = useState(0);
    const [shown, setShown] = useState(false);

    async function next() {
        setShown(false);
        await sleep(200);
        setTarget((target) => (target + 1) % 3);
        setChatCount((chatCount) => (chatCount + 1) % 3);
        await sleep(200);
        setShown(true);
    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    useEffect(() => {
        const interval1 = setInterval(() => {
            setStart(!start);
            next();
        }, 1600);
        return () => {
            clearInterval(interval1);
        };
    })

    useEffect(() => {
        console.log(isFooterVisible);
      }, [isFooterVisible]);

    useEffect(() => {
        document.title = 'Cognido';
      }, []);

    const handleMouseMove1 = (e) => {
        const rect = e.currentTarget.getBoundingClientRect();

        const width = rect.width;
        const height = rect.height;

        let mouseX = e.clientX - rect.left - rect.width / 2;
        let mouseY = e.clientY - rect.top - rect.height / 2;

        if (mouseX <= 0) {
            mouseX = -Math.pow(Math.abs(mouseX), 1 / 3)
        } else {
            mouseX = Math.pow(Math.abs(mouseX), 1 / 3)
        }

        if (mouseY <= 0) {
            mouseY = -Math.pow(Math.abs(mouseY), 1 / 3)
        } else {
            mouseY = Math.pow(Math.abs(mouseY), 1 / 3)
        }

        setMouse1([mouseX, mouseY])
        setMouse2([mouseX, mouseY])
    };

    return (
        <div className="rel">
            {(!isCog1Visible && (!isCog4Visible || isCog3Visible) && (!isFooterVisible || isCog6Visible)) &&
                <Header background={"blb"} btnShown={!isBtnVisible} sticky={true} />
            } 
            {!(!isCog1Visible && (!isCog4Visible || isCog3Visible) && (!isFooterVisible || isCog6Visible)) &&
                <Header background={"blb"} btnShown={!isBtnVisible} sticky={false} />
            } 
            <div className="sct blb fxc aic" ref={cog1Ref}
                onMouseMove={handleMouseMove1}
            >
                <div className="c cog1 pd9624">
                    <div className="cog1t">
                        <Nido />
                        <motion.div
                            className="cog1as"
                            animate={{
                                x: 20 + (mouse1[0] * 0.7),
                                y: 180 + (mouse1[1] * 1.0)
                            }}
                        ><img src={coin} /></motion.div>
                        <motion.div
                            className="cog1as"
                            initial={{
                                scale: 0.92
                            }}
                            animate={{
                                x: 240 + (mouse1[0] * 0.8),
                                y: 220 + (mouse1[1] * 0.9)
                            }}
                        ><img src={bubble} /></motion.div>
                        <motion.div
                            className="cog1as"
                            animate={{
                                x: 188 + (mouse1[0] * 0.6),
                                y: 20 + (mouse1[1] * 0.8)
                            }}
                        ><img src={chat3} /></motion.div>
                    </div>
                    <div className="cog1b fxc gp32">
                        <div className="fnts28 fcw fntw800">
                            Play mini games against others & improve your skills.
                        </div>
                        <div className="cog1bb" ref={btnRef}>
                            <Button text={"GET THE APP"} size={"btn"} primary={"bw"} secondary={"blbsec"} color={"fclb"} onClick={() => window.location.href = "#download"} />
                            <div className="btnnb fnts17 fcw fntw800">MORE INFO</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sct fxc aic">
                <div className="c fxc aic gp64 pd6424">
                    <div className="cog2c">
                        <div className="cog2ct cog2ct1">
                            <motion.div className="cog2rt fxc jcc aic fcw fnts24 fntw800"
                                initial={{
                                    backgroundColor: "#3CD898"
                                }}
                                animate={{
                                    backgroundColor: !start ? "#3CD898" : "#44B0EE"
                                }}
                                transition={{
                                    duration: 0.24
                                }}
                            >
                                {!start && <span>TAP</span>}
                                {start && <span>WAIT</span>}
                            </motion.div>
                            <AnimatePresence>
                                {shown &&
                                    <motion.div className="cog2trgt1"
                                        initial={{
                                            scale: 0.4,
                                            x: [108, -102, 80][target],
                                            y: [-104, 6, 112][target],
                                            opacity: 1.0
                                        }}
                                        animate={{
                                            scale: 1.0
                                        }}
                                        exit={{
                                            scale: 0.4,
                                            opacity: 0
                                        }}
                                        transition={{
                                            type: shown ? "spring" : "linear",
                                            duration: 0.24,
                                        }}
                                    >
                                        <div className="cog2trgt2">
                                            <div className="cog2trgt3">

                                            </div>
                                        </div>
                                    </motion.div>
                                }
                            </AnimatePresence>
                        </div>
                        <div className="cog2cb">
                            <div className="fnts22 fclg fntw700">
                                TRAIN YOUR BRAIN
                            </div>
                            <p className="fnts18 fntw300 fcdg">
                                Cognido is a fun way to improve your <span className="fntw600 fclb">Memory</span>, speed up your <span className="fntw600 fclb">Reaction Time</span>, refine your <span className="fntw600 fclb">Math Skills</span> and expand your <span className="fntw600 fclb">Knowledge</span> with a collection of more than 12 skill-based mini games.
                            </p>
                        </div>
                    </div>
                    <div className="cog2c cog2cl">
                        <div className="cog2ct cog2ct2">
                            <div className="fnts40 fntw800 fcbl">
                                vs
                            </div>
                            <motion.div className="cog2ddivs fxr"
                                initial={{
                                    rotateZ: "-45deg"
                                }}
                            >
                                <motion.div className="cog2ddiv"
                                    initial={{
                                        height: 10,
                                        width: 10,
                                        backgroundColor: "#4A9CFB44",
                                        color: "#FFFFFF00"
                                    }}
                                    animate={{
                                        width: target >= 1 ? 120 : 10
                                    }}
                                >
                                   _ 
                                </motion.div>
                                <motion.div className="cog2ddiv blb"
                                    initial={{
                                        height: 10,
                                        width: 10,
                                        backgroundColor: "#4A9CFB44",
                                        color: "#FFFFFF00"
                                    }}
                                    animate={{
                                        width: target >= 1 ? 120 : 10
                                    }}
                                >
                                    _
                                </motion.div>
                            </motion.div>
                            <motion.div className="cog2ct2u fxc aic gp8"
                                initial={{
                                    x: -80,
                                    y: -80,
                                    scale: 0.4,
                                    opacity: 0
                                }}
                                animate={{
                                    opacity: target >= 1 ? 1 : 0,
                                    scale: target >= 1 ? 1 : 0.4,
                                }}
                            >
                                <motion.div className="cog2ddiv fxc jcc aic fnts40 fntw800"
                                    initial={{
                                        height: 84,
                                        width: 84,
                                        borderRadius: 42,
                                        backgroundColor: "#F8324C",
                                        color: "#FFFFFF",
                                    }}
                                >
                                    Y
                                </motion.div>
                                <motion.div className="cog2ddiv fnts16 fntw700 fcb"
                                    initial={{
                                        height: 16,
                                    }}
                                    animate={{

                                    }}
                                >
                                    You
                                </motion.div>
                            </motion.div>
                            <motion.div className="cog2ct2u fxc aic gp8"
                                initial={{
                                    x: 80,
                                    y: 80,
                                    scale: 0.4,
                                    opacity: 0
                                }}
                                animate={{
                                    opacity: target >= 1 ? 1 : 0,
                                    scale: target >= 1 ? 1 : 0.4,
                                }}
                            >
                                <motion.div className="cog2ddiv fxc jcc aic fnts40 fntw800"
                                    initial={{
                                        height: 84,
                                        width: 84,
                                        borderRadius: 42,
                                        backgroundColor: "#7472E7",
                                        color: "#FFFFFF",
                                    }}
                                >
                                    O
                                </motion.div>
                                <motion.div className="cog2ddiv fnts16 fntw700 fcb"
                                    initial={{
                                        height: 16,
                                    }}
                                    animate={{
                                        
                                    }}
                                >
                                    Opponent
                                </motion.div>
                            </motion.div>
                        </div>
                        <div className="cog2cb">
                            <div className="fnts22 fclg fntw700">
                                PLAY WITH FRIENDS
                            </div>
                            <p className="fnts18 fntw300 fcdg">
                                Are you smarter than your friends? Let's find out. Challenge them to a duel and play 5 rounds of <span className="fntw600 fclb">skill-based mini games</span> against them. Or try to beat one of our <span className="fntw600 fclb">20+ bots.</span>
                            </p>
                        </div>
                    </div>
                    <div className="cog2c">
                        <div className="cog2ct cog2ct3">
                            <motion.div
                                initial={{
                                    y: -80,
                                    x: 56
                                }}
                                animate={{
                                    opacity: chatCount >= 2 ? 1 : 0,
                                    scale: chatCount >= 2 ? 1 : 0.4
                                }}
                            >
                                <img src={chat1} />
                            </motion.div>
                            <motion.div
                                initial={{
                                    y: 0,
                                    x: -56
                                }}
                                animate={{
                                    opacity: chatCount >= 1 ? 1 : 0,
                                    scale: chatCount >= 1 ? 1 : 0.4
                                }}
                            >
                                <img src={chat2} />
                            </motion.div>
                            <motion.div
                                initial={{
                                    y: 80,
                                    x: 24
                                }}
                                animate={{
                                    opacity: chatCount >= 0 ? 1 : 0,
                                    scale: chatCount >= 0 ? 1 : 0.4
                                }}
                            >
                                <img src={chat3} />
                            </motion.div>
                            <motion.div className="fnts48 fntw800"
                                initial={{
                                    color: "#F8324C",
                                    x: 112,
                                    rotateZ: "12deg",
                                    scale: 0.96
                                }}
                                animate={{
                                    rotateZ: "20deg",
                                    scale: 1
                                }}
                                transition={{
                                    duration: 1.0, ease: "easeInOut", repeat: Infinity, repeatType: "reverse"
                                }}
                            >
                                !
                            </motion.div>
                            <motion.div className="fnts48 fntw800"
                                initial={{
                                    color: "#4A9CFB",
                                    x: -80,
                                    y: -80,
                                    rotateZ: "4deg",
                                    scale: 0.96
                                }}
                                animate={{
                                    rotateZ: "-4deg",
                                    scale: 1
                                }}
                                transition={{
                                    duration: 1.0, ease: "easeInOut", repeat: Infinity, repeatType: "reverse"
                                }}
                            >
                                #
                            </motion.div>
                            <motion.div className="fnts48 fntw800"
                                initial={{
                                    color: "#7472E7",
                                    x: -104,
                                    y: 88,
                                    rotateZ: "8deg",
                                    scale: 0.96
                                }}
                                animate={{
                                    rotateZ: "0deg",
                                    scale: 1
                                }}
                                transition={{
                                    duration: 1.0, ease: "easeInOut", repeat: Infinity, repeatType: "reverse"
                                }}
                            >
                                ?
                            </motion.div>
                        </div>
                        <div className="cog2cb">
                            <div className="fnts22 fclg fntw700">
                                GET TRASHTALKED
                            </div>
                            <p className="fnts18 fntw300 fcdg">
                            <span className="fntw600 fclb">Nido</span> is our mascot. He doesn't really like humans and thinks most of them are stupid. If you fail one of our games he <span className="fntw600 fclb">likes to tease you and comment your gameplay</span>. So you better do your best so you don't get trashtalked.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sct fxc aic cog3sct" ref={cog3Ref} id="download" name="download"
                onMouseMove={handleMouseMove1}
            >
                <div className="c fxc aic jcc pd9624 gp48 cog3">
                    <div className="fxc aic jcc gp24">
                        <div className="fnts32 fntw800 fcb">
                            Show them what you got!
                        </div>
                        <p className="fnts18 fntw400 fclg">
                            Download the App to test your skills and compete against others.
                        </p>
                    </div>
                    <img src={appstore} onClick={() => {
                    window.open("https://apps.apple.com/de/app/cognido-minispiel-duelle/id6670284138");
                }} />
                    <div className="cog3g fxc jcc aic"

                    >
                        <motion.div className="cog3trgt1"
                            initial={{
                                scale: 1.1
                            }}
                            animate={{
                                x: 40 + (mouse1[0] * 0.3),
                                y: -100 + (mouse1[1] * 0.7),
                            }}
                        >
                            <div className="cog2trgt2">
                                <div className="cog2trgt3">

                                </div>
                            </div>
                        </motion.div>
                        <motion.div
                            animate={{
                                x: -140 + (mouse1[0] * 0.7),
                                y: 20 + (mouse1[1] * 0.8)
                            }}
                        ><img src={gold} /></motion.div>
                        <motion.div
                            animate={{
                                x: 180 + (mouse1[0] * 0.6),
                                y: -280 + (mouse1[1] * 0.9)
                            }}
                        ><img src={silver} /></motion.div>
                        <motion.div
                            animate={{
                                x: -200 + (mouse1[0] * 0.5),
                                y: -240 + (mouse1[1] * 1.0)
                            }}
                        ><img src={diamond} /></motion.div>
                        <motion.div
                            animate={{
                                x: 120 + (mouse1[0] * 0.6),
                                y: 120 + (mouse1[1] * 0.8)
                            }}
                        ><img src={bubble} /></motion.div>
                        <motion.div
                        initial={{
                            scale: 1.2
                        }}
                            animate={{
                                x: 400 + (mouse1[0] * 0.5),
                                y: -100 + (mouse1[1] * 0.9)
                            }}
                        ><img src={coin} /></motion.div>
                        <motion.div
                        initial={{
                            scale: 1.2
                        }}
                            animate={{
                                x: -60 + (mouse1[0] * 0.4),
                                y: 200 + (mouse1[1] * 0.8)
                            }}
                        ><img src={duel} /></motion.div>
                        <motion.div
                        initial={{
                            scale: 1.6
                        }}
                            animate={{
                                x: -400 + (mouse1[0] * 0.4),
                                y: -80 + (mouse1[1] * 0.8)
                            }}
                        ><img src={globe} /></motion.div>
                        <motion.div className="cog3trgt1"
                        initial={{
                            scale: 1.4
                        }}
                            animate={{
                                x: -480 + (mouse1[0] * 0.3),
                                y: 200 + (mouse1[1] * 0.7),
                            }}
                        >
                            <div className="cog2trgt2">
                                <div className="cog2trgt3">

                                </div>
                            </div>
                        </motion.div>
                        <motion.div className="cog3trgt1"
                        initial={{
                            scale: 1.4
                        }}
                            animate={{
                                x: 680 + (mouse1[0] * 0.3),
                                y: -240 + (mouse1[1] * 0.7),
                            }}
                        >
                            <div className="cog2trgt2">
                                <div className="cog2trgt3">

                                </div>
                            </div>
                        </motion.div>
                        <motion.div
                        initial={{
                            scale: 1.8
                        }}
                            animate={{
                                x: 720 + (mouse1[0] * 0.5),
                                y: 140 + (mouse1[1] * 1.0)
                            }}
                        ><img src={diamond} /></motion.div>
                        <motion.div
                        initial={{
                            scale: 1.8
                        }}
                            animate={{
                                x: -720 + (mouse1[0] * 0.5),
                                y: -180 + (mouse1[1] * 0.9)
                            }}
                        ><img src={coin} /></motion.div>
                        <motion.div
                        initial={{
                            scale: 1.4
                        }}
                            animate={{
                                x: 420 + (mouse1[0] * 0.6),
                                y: 280 + (mouse1[1] * 0.9)
                            }}
                        ><img src={silver} /></motion.div>
                        <motion.div
                        initial={{
                            scale: 1.4
                        }}
                            animate={{
                                x: -480 + (mouse1[0] * 0.4),
                                y: -480 + (mouse1[1] * 0.8)
                            }}
                        ><img src={duel} /></motion.div>
                        <motion.div
                        initial={{
                            scale: 1.1
                        }}
                            animate={{
                                x: 520 + (mouse1[0] * 0.6),
                                y: -480 + (mouse1[1] * 0.8)
                            }}
                        ><img src={bubble} /></motion.div>
                    </div>
                </div>
            </div>
            <div className="sct plswav">
                <img src={pluswaves} />
            </div>
            <div className="sct bg fxc aic" ref={cog4Ref} id="plus" name="plus"> 
                <div className="c pd9624 fxc aic gp16">
                    <div className="cog4t fxc gp24">
                        <div className="cog4h">
                            <div className="fnts32 fntw800 fcw">
                                Cognido Plus
                            </div>
                            <img src={plus} />
                        </div>
                        <p className="fnts18 fntw300 fcw">
                            Get full <span className="fntw600">access to all of our features</span> to train and improve your skills, try to beat all of our bots and play without any interruptions. <span className="fntw600">One week for free</span>, then $3.99/month.
                        </p>
                    </div>
                    <div className="cog4b">
                        <motion.div className="cog4bc fxr aic gp12"
                            initial={{
                                backgroundColor: "#FFFFFF44",
                                color: "#FFFFFF",
                                border: "4px solid #9FE9CB",
                            }}
                            whileHover={{
                                scale: 1.04,
                                backgroundColor: "#FFFFFF",
                                color: "#282828",
                            }}
                        >
                            <img src={training} />
                            <p>UNLOCK TRAINING MODE</p>
                        </motion.div>
                        <motion.div className="cog4bc cog4bcr fxr aic gp12"
                            initial={{
                                backgroundColor: "#FFFFFF44",
                                color: "#FFFFFF",
                                border: "4px solid #9FE9CB",
                            }}
                            whileHover={{
                                scale: 1.04,
                                backgroundColor: "#FFFFFF",
                                color: "#282828",
                            }}
                        >
                            <img src={bot} />
                            <p>UNLOCK ALL BOTS</p>
                        </motion.div>
                        <motion.div className="cog4bc fxr aic gp12"
                            initial={{
                                backgroundColor: "#FFFFFF44",
                                color: "#FFFFFF",
                                border: "4px solid #9FE9CB",
                            }}
                            whileHover={{
                                scale: 1.04,
                                backgroundColor: "#FFFFFF",
                                color: "#282828",
                            }}
                        >
                            <motion.div
                                initial={{
                                    scale: 0.72
                                }}
                            >
                                <img src={bubble} />
                            </motion.div>
                            <p>BUBBLES EVERY 10MIN</p>
                        </motion.div>
                        <motion.div className="cog4bc cog4bcr fxr aic gp12"
                            initial={{
                                backgroundColor: "#FFFFFF44",
                                color: "#FFFFFF",
                                border: "4px solid #9FE9CB",
                            }}
                            whileHover={{
                                scale: 1.04,
                                backgroundColor: "#FFFFFF",
                                color: "#282828",
                            }}
                        >
                            <motion.div
                                initial={{
                                    scale: 0.84
                                }}
                            >
                                <img src={none} />
                            </motion.div>
                            <p>NO MORE ADS</p>
                        </motion.div>
                    </div>
                    <div className="cog3btn">
                        <Button text={"START FREE TRIAL"} size={"btn"} primary={"bw"} secondary={"bgsec"} color={"fcg"} onClick={() => window.location.href = "#download"} />
                    </div>
                </div>
            </div>
            <motion.div className="sct plswav"
                initial={{
                    rotateZ: "180deg"
                }}
            >
                <img src={pluswaves} />
            </motion.div>
            
            <div className="sct fxc aic" ref={cog6Ref} id="faq" name="faq">
                <div className="c pd9624 fxc aic gp16">
                    <div className="cog4t fxc gp24">
                        <div className="fnts32 fntw800 fcb">
                            Frequently Asked Questions
                        </div>
                        <p className="fnts18 fntw300 fcdg">
                            If you have any other questions you can contact us via e-mail <span className="fntw600">support@cognido.io</span> or through our social media channels.
                        </p>
                    </div>
                    <div className="cog6b fxc gp20">
                        <motion.div className="cog6bc fxc gp20"
                        onClick={() => setFlippedQ1(!flippedQ1)}
                            whileHover={{
                                border: "3px solid #44B0EE"
                            }}
                        >
                            <div className="cog6bct fxr jcsb fnts19 fcb aic fntw600"
                                
                            >
                                <div>How do I add friends on Cognido?</div>
                                <motion.div
                                    animate={{
                                        rotateX: flippedQ1 ? 180 : 0,
                                        y: flippedQ1 ? -4 : 0
                                    }}
                                >
                                    <img src={arrowDown} />
                                </motion.div>
                            </div>
                            {flippedQ1 && 
                                <motion.div className="cog6bcb fxc"
                                    exit={{
                                        scaleY: 0
                                    }}
                                >
                                    <p className="fnts17 fntw300 fcb">
                                        If you want to add your friends to play against them you can go to your <span className="fntw600">Profile</span> page on the App. Once you are there tap on the <span className="fntw600">"Add Friends"-Button</span> to go to your <span className="fntw600">Friends</span> page and search for your friends username.<br></br><br></br>Once you found him you can hit the <span className="fntw600">"Follow"-Button</span>. You can start a duel against your friend after he accepts your requests. 
                                    </p>
                                </motion.div>
                            }
                        </motion.div>
                        <motion.div className="cog6bc fxc gp20"
                        onClick={() => setFlippedQ2(!flippedQ2)}
                            whileHover={{
                                border: "3px solid #44B0EE"
                            }}
                        >
                            <div className="cog6bct fxr jcsb fnts19 fcb aic fntw600"
                                
                            >
                                <div>How does the Time Perception game work?</div>
                                <motion.div
                                    animate={{
                                        rotateX: flippedQ2 ? 180 : 0,
                                        y: flippedQ2 ? -4 : 0
                                    }}
                                >
                                    <img src={arrowDown} />
                                </motion.div>
                            </div>
                            {flippedQ2 && 
                                <motion.div className="cog6bcb fxc"
                                    
                                >
                                    <p className="fnts17 fntw300 fcb">
                                        <span className="fntw600">Tap & Hold</span> the blue box and the box will start to grow. After a few seconds the box becomes invisible. Now it's on you to <span className="fntw600">release your finger once you think 100% is reached</span>. When in a duel, however got closest to 100% wins. <br></br><br></br> But <span className="fntw600">watch out</span>, don't mistake it for the click speed game or you will end up with a 20% score.
                                    </p>
                                </motion.div>
                            }
                        </motion.div>
                        <motion.div className="cog6bc fxc gp20"
                        onClick={() => setFlippedQ3(!flippedQ3)}
                            whileHover={{
                                border: "3px solid #44B0EE"
                            }}
                        >
                            <div className="cog6bct fxr jcsb fnts19 fcb aic fntw600"
                                
                            >
                                <div>Why do I not get any ranked points or coins?</div>
                                <motion.div
                                    animate={{
                                        rotateX: flippedQ3 ? 180 : 0,
                                        y: flippedQ3 ? -4 : 0
                                    }}
                                >
                                    <img src={arrowDown} />
                                </motion.div>
                            </div>
                            {flippedQ3 && 
                                <motion.div className="cog6bcb fxc"
                                    
                                >
                                    <p className="fnts17 fntw300 fcb">
                                        You'll <span className="fntw600">not</span> get any ranked points or coins when you play against one of our bots. When you're playing against friends you will get coins, but no ranked points, to avoid cheating and boosting.
                                    </p>
                                </motion.div>
                            }
                        </motion.div>
                        <motion.div className="cog6bc fxc gp20"
                        onClick={() => setFlippedQ4(!flippedQ4)}
                            whileHover={{
                                border: "3px solid #44B0EE"
                            }}
                        >
                            <div className="cog6bct fxr jcsb fnts19 fcb aic fntw600"
                                
                            >
                                <div>How do I delete my account?</div>
                                <motion.div
                                    animate={{
                                        rotateX: flippedQ4 ? 180 : 0,
                                        y: flippedQ4 ? -4 : 0
                                    }}
                                >
                                    <img src={arrowDown} />
                                </motion.div>
                            </div>
                            {flippedQ4 && 
                                <motion.div className="cog6bcb fxc"
                                    
                                >
                                    <p className="fnts17 fntw300 fcb">
                                        You need to go to the <span className="fntw600">Profile</span> page in our App if you want to delete your account. Scroll down until you find the <span className="fntw600">"Delete account"-Button.</span><br></br><br></br>
                                        <span className="fntw600">Attention:</span> If this action fails you might need to logout and re-login again because deleting your account needs recent authenticaion.
                                    </p>
                                </motion.div>
                            }
                        </motion.div>
                        <motion.div className="cog6bc fxc gp20"
                            onClick={() => setFlippedQ5(!flippedQ5)}
                            whileHover={{
                                border: "3px solid #44B0EE"
                            }}
                        >
                            <div className="cog6bct fxr jcsb fnts19 fcb aic fntw600"
                                
                            >
                                <div>How do I cancel my Cognido Plus subscription?</div>
                                <motion.div
                                    animate={{
                                        rotateX: flippedQ5 ? 180 : 0,
                                        y: flippedQ5 ? -4 : 0
                                    }}
                                >
                                    <img src={arrowDown} />
                                </motion.div>
                            </div>
                            {flippedQ5 && 
                                <motion.div className="cog6bcb fxc"
                                    
                                >
                                    <p className="fnts17 fntw300 fcb">
                                    You can cancel your Cognido Plus subscription at <span className="fntw600">any time.</span><br></br><br></br>You will need to cancel your subscription based on how you originally started the subscription, e.g. through the App Stores subscriptions page.<br></br><br></br>Deleting the app and/or your account does not cancel your subscription.
                                    </p>
                                </motion.div>
                            }
                        </motion.div>
                        <motion.div className="cog6bc fxc gp20"
                        onClick={() => setFlippedQ6(!flippedQ6)}
                            whileHover={{
                                border: "3px solid #44B0EE"
                            }}
                        >
                            <div className="cog6bct fxr jcsb fnts19 fcb aic fntw600"
                                
                            >
                                <div>How do I request a refund?</div>
                                <motion.div
                                    animate={{
                                        rotateX: flippedQ6 ? 180 : 0,
                                        y: flippedQ6 ? -4 : 0
                                    }}
                                >
                                    <img src={arrowDown} />
                                </motion.div>
                            </div>
                            {flippedQ6 && 
                                <motion.div className="cog6bcb fxc"
                                    
                                >
                                    <p className="fnts17 fntw300 fcb">
                                    Generally, all charges for in-app purchases are nonrefundable, and there are no refunds or credits for partially used periods. It is <span className="fntw600">up to the discretion of the processor</span> (Google Play or Apple) to successfully process the refund request.
                                    </p>
                                </motion.div>
                            }
                        </motion.div>
                    </div>
                </div>
            </div>
            <div ref={footerRef}>
                <Footer />
            </div>
            <Cookies />
        </div>
    )
}