import { useEffect, useState, useRef } from "react";
import Footer from "../../components/footer"
import Header from "../../components/header";
import Button from "../../components/button";
import Nido from "../../nido/nido";

import coin from "../../assets/assets/coin.png"
import chat from "../../assets/assets/chatbubble.png"

import ourstory from "../../assets/assets/ourstory.png"
import ourmission from "../../assets/assets/ourmission.png"

import training from "../../assets/assets/training.png"
import none from "../../assets/assets/none.png"
import bot from "../../assets/assets/bot.png"
import bubble from "../../assets/assets/bubble.png"

import plus from "../../assets/assets/plus.stamp.png"

import chat1 from "../../assets/assets/chatbubble1.png"
import chat2 from "../../assets/assets/chatbubble2.png"
import chat3 from "../../assets/assets/chatbubble3.png"

import appstore from "../../assets/assets/appstorebtn.png"

import arrowDown from "../../assets/assets/arrow.down.png"

import diamond from "../../assets/assets/diamond.png"
import gold from "../../assets/assets/gold.png"
import silver from "../../assets/assets/silver.png"
import globe from "../../assets/assets/globe.png"
import duel from "../../assets/assets/duel.png"
import pluswaves from "../../assets/assets/pluswaves.png"

import { motion, AnimatePresence } from "framer-motion";
import Backdrop from "../../components/backdrop";
import Cookies from "../../components/cookies";

const useIntersection = (element, rootMargin) => {
    const [isVisible, setState] = useState(false);
  
    useEffect(() => {
      const current = element?.current;
      const observer = new IntersectionObserver(
        ([entry]) => {
          setState(entry.isIntersecting);
        },
        { rootMargin }
      );
      current && observer?.observe(current);
  
      return () => current && observer.unobserve(current);
    }, []);
  
    return isVisible;
  };

export default function Press() {

    const cog1Ref = useRef(null);
    const isCog1Visible = useIntersection(cog1Ref, "0px");
    const cog3Ref = useRef(null);
    const isCog3Visible = useIntersection(cog3Ref, "0px");
    const cog4Ref = useRef(null);
    const isCog4Visible = useIntersection(cog4Ref, "0px");
    const cog6Ref = useRef(null);
    const isCog6Visible = useIntersection(cog6Ref, "0px");
    const footerRef = useRef(null);
    const isFooterVisible = useIntersection(footerRef, "0px");
    const btnRef = useRef(null);
    const isBtnVisible = useIntersection(btnRef, "0px");

    const [mouse1, setMouse1] = useState([0, 0])
    const [mouse2, setMouse2] = useState([0, 0])

    const [flippedQ1, setFlippedQ1] = useState(false)
    const [flippedQ2, setFlippedQ2] = useState(false)
    const [flippedQ3, setFlippedQ3] = useState(false)
    const [flippedQ4, setFlippedQ4] = useState(false)
    const [flippedQ5, setFlippedQ5] = useState(false)
    const [flippedQ6, setFlippedQ6] = useState(false)

    const [start, setStart] = useState(null);
    const [target, setTarget] = useState(0);
    const [chatCount, setChatCount] = useState(0);
    const [shown, setShown] = useState(false);

    async function next() {
        setShown(false);
        await sleep(200);
        setTarget((target) => (target + 1) % 3);
        setChatCount((chatCount) => (chatCount + 1) % 3);
        await sleep(200);
        setShown(true);
    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    useEffect(() => {
        const interval1 = setInterval(() => {
            setStart(!start);
            next();
        }, 1600);
        return () => {
            clearInterval(interval1);
        };
    })

    useEffect(() => {
        console.log(isFooterVisible);
      }, [isFooterVisible]);

    useEffect(() => {
        document.title = 'Cognido';
      }, []);

    const handleMouseMove1 = (e) => {
        const rect = e.currentTarget.getBoundingClientRect();

        const width = rect.width;
        const height = rect.height;

        let mouseX = e.clientX - rect.left - rect.width / 2;
        let mouseY = e.clientY - rect.top - rect.height / 2;

        if (mouseX <= 0) {
            mouseX = -Math.pow(Math.abs(mouseX), 1 / 3)
        } else {
            mouseX = Math.pow(Math.abs(mouseX), 1 / 3)
        }

        if (mouseY <= 0) {
            mouseY = -Math.pow(Math.abs(mouseY), 1 / 3)
        } else {
            mouseY = Math.pow(Math.abs(mouseY), 1 / 3)
        }

        setMouse1([mouseX, mouseY])
        setMouse2([mouseX, mouseY])
    };

    return (
        <div className="rel">
            <Header background={"blb"} btnShown={true} sticky={true} />
            <div className="sct fxc aic">
                <div className="c fxc aic gp64 pd6424">
                    <div className="cog2c">
                        <div className="cog2ct cog2ct1">
                        <img src={ourmission} />
                        </div>
                        <div className="cog2cb">
                            <div className="fnts22 fclg fntw700">
                                OUR MISSION
                            </div>
                            <p className="fnts18 fntw300 fcdg">
                                At Cognido, our mission is to <span className="fntw600 fclb">make brain training engaging, social, and accessible for the next generation</span>. In a world where most platforms and apps often overwhelm the mind with endless scrolling and overstimulation, we offer a refreshing alternative - one that’s <span className="fntw600 fclb">fun, challenging, and truly beneficial</span>. Unlike other brain training apps, Cognido is a multiplayer game where you can go <span className="fntw600 fclb">head-to-head with your friends</span> and other players from around the world.
                            </p>
                        </div>
                    </div>
                    <div className="cog2c cog2cl">
                        <div className="cog2ct cog2ct2">
                            <img src={ourstory} />
                        </div>
                        <div className="cog2cb">
                            <div className="fnts22 fclg fntw700">
                                OUR STORY
                            </div>
                            <p className="fnts18 fntw300 fcdg">
                                Hi, I'm David, solo-developer of Cognido. Cognido began as a <span className="fntw600 fclb">passion project</span> during my time as a university student. I’ve always been drawn to skill-based games, ones that <span className="fntw600 fclb">challenge the mind</span>. Before Cognido, I developed some similar games that laid the groundwork for what would eventually become Cognido. Starting in September 2024, Cognido has more than 35k players, has spent some days on the <span className="fntw600 fclb">#1 seat on the trivia games leaderboard</span> in the German App Store and is growing faster than ever.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sct fxc aic cog3sct" ref={cog3Ref} id="download" name="download"
                onMouseMove={handleMouseMove1}
            >
                <div className="c fxc aic jcc pd9624 gp48 cog3">
                    <div className="fxc aic jcc gp24">
                        <div className="fnts32 fntw800 fcb">
                            See it for yourself!
                        </div>
                        <p className="fnts18 fntw400 fclg">
                            Experience brain training for the next generation.
                        </p>
                    </div>
                    <img src={appstore} onClick={() => {
                    window.open("https://apps.apple.com/de/app/cognido-minispiel-duelle/id6670284138");
                }} />
                    <div className="cog3g fxc jcc aic"

                    >
                        <motion.div className="cog3trgt1"
                            initial={{
                                scale: 1.1
                            }}
                            animate={{
                                x: 40 + (mouse1[0] * 0.3),
                                y: -100 + (mouse1[1] * 0.7),
                            }}
                        >
                            <div className="cog2trgt2">
                                <div className="cog2trgt3">

                                </div>
                            </div>
                        </motion.div>
                        <motion.div
                            animate={{
                                x: -140 + (mouse1[0] * 0.7),
                                y: 20 + (mouse1[1] * 0.8)
                            }}
                        ><img src={gold} /></motion.div>
                        <motion.div
                            animate={{
                                x: 180 + (mouse1[0] * 0.6),
                                y: -280 + (mouse1[1] * 0.9)
                            }}
                        ><img src={silver} /></motion.div>
                        <motion.div
                            animate={{
                                x: -200 + (mouse1[0] * 0.5),
                                y: -240 + (mouse1[1] * 1.0)
                            }}
                        ><img src={diamond} /></motion.div>
                        <motion.div
                            animate={{
                                x: 120 + (mouse1[0] * 0.6),
                                y: 120 + (mouse1[1] * 0.8)
                            }}
                        ><img src={bubble} /></motion.div>
                        <motion.div
                        initial={{
                            scale: 1.2
                        }}
                            animate={{
                                x: 400 + (mouse1[0] * 0.5),
                                y: -100 + (mouse1[1] * 0.9)
                            }}
                        ><img src={coin} /></motion.div>
                        <motion.div
                        initial={{
                            scale: 1.2
                        }}
                            animate={{
                                x: -60 + (mouse1[0] * 0.4),
                                y: 200 + (mouse1[1] * 0.8)
                            }}
                        ><img src={duel} /></motion.div>
                        <motion.div
                        initial={{
                            scale: 1.6
                        }}
                            animate={{
                                x: -400 + (mouse1[0] * 0.4),
                                y: -80 + (mouse1[1] * 0.8)
                            }}
                        ><img src={globe} /></motion.div>
                        <motion.div className="cog3trgt1"
                        initial={{
                            scale: 1.4
                        }}
                            animate={{
                                x: -480 + (mouse1[0] * 0.3),
                                y: 200 + (mouse1[1] * 0.7),
                            }}
                        >
                            <div className="cog2trgt2">
                                <div className="cog2trgt3">

                                </div>
                            </div>
                        </motion.div>
                        <motion.div className="cog3trgt1"
                        initial={{
                            scale: 1.4
                        }}
                            animate={{
                                x: 680 + (mouse1[0] * 0.3),
                                y: -240 + (mouse1[1] * 0.7),
                            }}
                        >
                            <div className="cog2trgt2">
                                <div className="cog2trgt3">

                                </div>
                            </div>
                        </motion.div>
                        <motion.div
                        initial={{
                            scale: 1.8
                        }}
                            animate={{
                                x: 720 + (mouse1[0] * 0.5),
                                y: 140 + (mouse1[1] * 1.0)
                            }}
                        ><img src={diamond} /></motion.div>
                        <motion.div
                        initial={{
                            scale: 1.8
                        }}
                            animate={{
                                x: -720 + (mouse1[0] * 0.5),
                                y: -180 + (mouse1[1] * 0.9)
                            }}
                        ><img src={coin} /></motion.div>
                        <motion.div
                        initial={{
                            scale: 1.4
                        }}
                            animate={{
                                x: 420 + (mouse1[0] * 0.6),
                                y: 280 + (mouse1[1] * 0.9)
                            }}
                        ><img src={silver} /></motion.div>
                        <motion.div
                        initial={{
                            scale: 1.4
                        }}
                            animate={{
                                x: -480 + (mouse1[0] * 0.4),
                                y: -480 + (mouse1[1] * 0.8)
                            }}
                        ><img src={duel} /></motion.div>
                        <motion.div
                        initial={{
                            scale: 1.1
                        }}
                            animate={{
                                x: 520 + (mouse1[0] * 0.6),
                                y: -480 + (mouse1[1] * 0.8)
                            }}
                        ><img src={bubble} /></motion.div>
                    </div>
                </div>
            </div>
            <motion.div className="sct plswav"
                initial={{
                    rotateZ: "180deg",
                    opacity: 0
                }}
            >
                <img src={pluswaves} />
            </motion.div>
            <div className="sct fxc aic" ref={cog4Ref} id="plus" name="plus"> 
                <div className="c bdb pd9648 fxc aic gp12 brrd24">
                    <div className="cog4t fxc gp24">
                        <div className="cog4h">
                            <div className="fnts32 fntw800 fcw">
                                Press Kit
                            </div>
                        </div>
                        <p className="fnts18 fntw300 fcw">
                            Download our logos, app screenshots, press images and more.
                        </p>
                    </div>
                    <div className="cog3btn">
                            <Button text={"DOWNLOAD PRESS KIT"} size={"btn"} primary={"bw"} secondary={"bdbsec"} color={"fcdb"} onClick={() => window.location.href = "https://www.dropbox.com/scl/fo/0kknwkxtxernyfpuacwqj/AFl6uSHJcBX5P3DTOcvauts?rlkey=e32pne4cogk5p4csmnu4vy7l2&st=askvkvig&dl=0"} />
                        </div>
                </div>
            </div>
            <motion.div className="sct plswav"
                initial={{
                    rotateZ: "180deg",
                    opacity: 0
                }}
            >
                <img src={pluswaves} />
            </motion.div>
            
            <div className="sct fxc aic" ref={cog6Ref} id="faq" name="faq">
                <div className="c pd9624 fxc aic gp16">
                    <div className="cog4t fxc gp24">
                        <div className="fnts32 fntw800 fcb">
                            Contact
                        </div>
                        <p className="fnts18 fntw300 fcdg">
                            For questions or inquiries specific to press matters, please e-mail <span className="fntw600 fclb">support@cognido.io</span> or take a look at the frequent questions below.
                        </p>
                    </div>
                    <div className="cog6b fxc gp20">
                        <motion.div className="cog6bc fxc gp20"
                        onClick={() => setFlippedQ1(!flippedQ1)}
                            whileHover={{
                                border: "3px solid #44B0EE"
                            }}
                        >
                            <div className="cog6bct fxr jcsb fnts19 fcb aic fntw600"
                                
                            >
                                <div>How long was Cognido in development?</div>
                                <motion.div
                                    animate={{
                                        rotateX: flippedQ1 ? 180 : 0,
                                        y: flippedQ1 ? -4 : 0
                                    }}
                                >
                                    <img src={arrowDown} />
                                </motion.div>
                            </div>
                            {flippedQ1 && 
                                <motion.div className="cog6bcb fxc"
                                    exit={{
                                        scaleY: 0
                                    }}
                                >
                                    <p className="fnts17 fntw300 fcb">
                                        Before Cognido I made a game with a similar idea that laid the groundwork for what would eventually become Cognido.<br></br><br></br>Overall it was a year of developing and constantly changing the apps features to reach the currently available version of Cognido.
                                    </p>
                                </motion.div>
                            }
                        </motion.div>
                        <motion.div className="cog6bc fxc gp20"
                        onClick={() => setFlippedQ2(!flippedQ2)}
                            whileHover={{
                                border: "3px solid #44B0EE"
                            }}
                        >
                            <div className="cog6bct fxr jcsb fnts19 fcb aic fntw600"
                                
                            >
                                <div>Are there plans for future updates?</div>
                                <motion.div
                                    animate={{
                                        rotateX: flippedQ2 ? 180 : 0,
                                        y: flippedQ2 ? -4 : 0
                                    }}
                                >
                                    <img src={arrowDown} />
                                </motion.div>
                            </div>
                            {flippedQ2 && 
                                <motion.div className="cog6bcb fxc"
                                    
                                >
                                    <p className="fnts17 fntw300 fcb">
                                        There is a huge update in development right now that will add many more mini games to bring more variety into the game. <br></br><br></br> With this update there is also a brand new game mode coming into the game. 'Clash' will bring a new way of competing to Cognido, where 4-6 players will play rounds of mini games and each round the player with the worst score will get eliminated until only one player, the winner, is left.
                                    </p>
                                </motion.div>
                            }
                        </motion.div>
                        <motion.div className="cog6bc fxc gp20"
                        onClick={() => setFlippedQ4(!flippedQ4)}
                            whileHover={{
                                border: "3px solid #44B0EE"
                            }}
                        >
                            <div className="cog6bct fxr jcsb fnts19 fcb aic fntw600"
                                
                            >
                                <div>Is the game free to play or premium?</div>
                                <motion.div
                                    animate={{
                                        rotateX: flippedQ4 ? 180 : 0,
                                        y: flippedQ4 ? -4 : 0
                                    }}
                                >
                                    <img src={arrowDown} />
                                </motion.div>
                            </div>
                            {flippedQ4 && 
                                <motion.div className="cog6bcb fxc"
                                    
                                >
                                    <p className="fnts17 fntw300 fcb">
                                        Playing skill-based mini games against your friends and other people from around the world with Cognido is free. <br></br><br></br>
                                        However, there is premium subscription that allows the user to train all of the mini games in a single player mode. With Cognido Plus you also get access to all of our bots and ads will get removed.
                                    </p>
                                </motion.div>
                            }
                        </motion.div>
                        <motion.div className="cog6bc fxc gp20"
                            onClick={() => setFlippedQ5(!flippedQ5)}
                            whileHover={{
                                border: "3px solid #44B0EE"
                            }}
                        >
                            <div className="cog6bct fxr jcsb fnts19 fcb aic fntw600"
                                
                            >
                                <div>What difficulty levels are available?</div>
                                <motion.div
                                    animate={{
                                        rotateX: flippedQ5 ? 180 : 0,
                                        y: flippedQ5 ? -4 : 0
                                    }}
                                >
                                    <img src={arrowDown} />
                                </motion.div>
                            </div>
                            {flippedQ5 && 
                                <motion.div className="cog6bcb fxc"
                                    
                                >
                                    <p className="fnts17 fntw300 fcb">
                                        Every mini game starts with a few pretty easy stages. However, the games increase in difficulty pretty quickly. But in the end it's not about beating the game, it's about being a little bit smarter and faster than the player or bot you are playing against.
                                    </p>
                                </motion.div>
                            }
                        </motion.div>
                    </div>
                </div>
            </div>
            <Cookies />
        </div>
    )
}