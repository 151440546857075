import Header from "../../components/header";
import { useEffect } from "react";

export default function Privacy() {

    useEffect(() => {
        document.title = 'Privacy Policy - Cognido';
    }, []);

    return (
        <div>
            <Header background={"bdb"} btnShown={false} sticky={true} />
            <div className="sct fxc aic">
                <div className="c fxc pd6424 gp32">
                    <div className="fxc gp8">
                        <div className="fnts32 fntw700 fcb">
                            Privacy Policy
                        </div>
                        <p className="fnts16 fntw400 fclg">
                            Last updated: 1.11.2024
                        </p>
                    </div>
                    <div className="fxc gp8">
                        <div className="fnts20 fntw600 fcb">
                        What data does "Cognido" collect about me, and why?
                        </div>
                        <p className="fnts16 fntw300 fcdg">
                        We collect your username, email and game related information (match data, coins, bubbles, friends, etc.). Your username will be displayed in the app. We collect your e-mail only for sending you a link to reset you password if you request one and for handling authentication.
                        </p>
                    </div>
                    <div className="fxc gp8">
                        <div className="fnts20 fntw600 fcb">
                        Does "Cognido" share data about me with others?
                        </div>
                        <p className="fnts16 fntw300 fcdg">
                        We do not sell or give information about you to other companies or services. However, we do use services from other companies to provide the app and website. The companies behind those services may collect data about you on their own, for their own purposes. Some of these services may be used to collect information about your online activities across different websites.
                        </p>
                    </div>
                    <div className="fxc gp8">
                        <div className="fnts20 fntw600 fcb">
                        Where does "Cognido" store data about me?
                        </div>
                        <p className="fnts16 fntw300 fcdg">
                        We store your user info and game related information on servers in Europe from Google Firebase.
                        </p>
                    </div>
                    <div className="fxc gp8">
                        <div className="fnts20 fntw600 fcb">
                        Other Services
                        </div>
                        <p className="fnts16 fntw300 fcdg">
                        We use the following services that collect personal data for the following purposes:
                        </p>
                        <div className="services-table">
                                <div className="services-table-heading">
                                    <div className="services-table-left">
                                        Services
                                    </div>
                                    <div className="services-table-center">
                                        Privacy Notice
                                    </div>
                                    <div className="services-table-right">
                                        Description
                                    </div>
                                </div>
                                <div className="divider-primary"></div>
                                <div className="services-table-cell">
                                    <div className="services-table-left">
                                        <a href="https://firebase.google.com">Firebase</a>
                                    </div>
                                    <div className="services-table-center">
                                        <a href="https://firebase.google.com/support/privacy">https:<wbr></wbr>//firebase.<wbr></wbr>google.<wbr></wbr>com/<wbr></wbr>support/<wbr></wbr>privacy</a>
                                    </div>
                                    <div className="services-table-right">
                                        We use Firebase to store your information.
                                    </div>
                                </div>
                                <div className="divider-secondary"></div>
                                <div className="services-table-cell">
                                    <div className="services-table-left">
                                        <a href="https://admob.google.com/home/">AdMob</a>
                                    </div>
                                    <div className="services-table-center">
                                        <a href="https://firebase.google.com/support/privacy">https:<wbr></wbr>//firebase.<wbr></wbr>google.<wbr></wbr>com/<wbr></wbr>support/<wbr></wbr>privacy</a>
                                    </div>
                                    <div className="services-table-right">
                                        We use Google AdMob to display ads in the app.
                                    </div>
                                </div>
                                <div className="divider-secondary"></div>
                                <div className="services-table-cell">
                                    <div className="services-table-left">
                                        <a href="https://www.revenuecat.com">RevenueCat</a>
                                    </div>
                                    <div className="services-table-center">
                                        <a href="https://www.revenuecat.com/privacy/">https:<wbr></wbr>//www.<wbr></wbr>revenuecat.<wbr></wbr>com/<wbr></wbr>privacy</a>
                                    </div>
                                    <div className="services-table-right">
                                        We use RevenueCat for handling in-app purchases and subscriptions.
                                    </div>
                                </div>
                                <div className="divider-secondary"></div>
                            </div>
                    </div>
                    <div className="fxc gp8">
                        <div className="fnts20 fntw600 fcb">
                        How can I contact "Cognido"?
                        </div>
                        <p className="fnts16 fntw300 fcdg">
                        You can send privacy-related questions, complaints, and requests to us at support@cognido.io.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}