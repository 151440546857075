import { motion } from "framer-motion";

export default function Backdrop(props) {
    return (
        <motion.div 
            className="bckdrp" 
            
        >
            {props.children}
        </motion.div>
    );
}