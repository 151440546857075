import head from "../assets/nido/head.png"
import leg1 from "../assets/nido/leg1.png"
import leg2 from "../assets/nido/leg2.png"
import { motion } from "framer-motion"

export default function Nido(props) {

    const size = props.size

    function outerLegRotation() {
        return 2
    }

    return (
        <motion.div className="nido"
            initial={{
                origin: 1,
                scale: 0.80,
            }}
        >
            <div className="nidol2s">
                <motion.div
                    animate={{
                        rotate: 2
                    }}
                    transition={{ duration: 1.0, ease: "easeInOut", repeat: Infinity,
                    repeatType: "reverse" }}
                >
                    <img className="nidol2" src={leg2} />
                </motion.div>
                <motion.div
                    animate={{
                        rotate: -2
                    }}
                    transition={{ duration: 1.0, ease: "easeInOut", repeat: Infinity,
                    repeatType: "reverse" }}
                >
                    <img className="nidol2 y180" src={leg2} />
                </motion.div>
            </div>
            <div className="nidol1s">
                <motion.div
                    initial={{
                        rotate: 2,
                        x: 0.8,
                        y: -0.8
                    }}
                    animate={{
                        rotate: 0
                    }}
                    transition={{ duration: 1.0, ease: "easeInOut", repeat: Infinity,
                    repeatType: "reverse" }}
                >
                    <img className="nidol1" src={leg1} />
                </motion.div>
                <motion.div
                    initial={{
                        rotate: -2,
                        x: -0.8
                    }}
                    animate={{
                        rotate: 0
                    }}
                    transition={{ duration: 1.0, ease: "easeInOut", repeat: Infinity,
                    repeatType: "reverse" }}
                >
                    <img className="nidol1 y180" src={leg1} />
                </motion.div>
            </div>
            <motion.div className="nidoh"
                    initial={{
                        scale: 1.016
                    }}
                    animate={{
                        scale: 1
                    }}
                    transition={{ duration: 1.0, ease: "easeInOut", repeat: Infinity,
                    repeatType: "reverse" }}
                >
                    <img className="nidoh" src={head} />
                    <div className="nidoeyes">
                        <div className="nidoeye">
                            <motion.div className="nidopupil"
                                initial={{
                                    y: 1
                                }}
                                animate={{
                                    x: [4, -4]
                                }}
                                transition={{ duration: 0.2, ease: "linear", repeat: Infinity, repeatType: "reverse", repeatDelay: 6.0, delay: 6.0 }}
                            >

                            </motion.div>
                            <motion.div className="nidoeyelid"
                                animate={{
                                    y: [75, 52, 75]
                                }}
                                transition={{ duration: 0.2, ease: "linear", repeat: Infinity, repeatDelay: 4.0, delay: 2.0 }}
                            >
                                
                            </motion.div>
                            <motion.div className="nidoeyelid"
                                
                                animate={{
                                    y: [-50, -40, -50]
                                }}
                                transition={{ duration: 0.2, ease: "linear", repeat: Infinity, repeatDelay: 4.0, delay: 2.0 }}
                            >
                                
                            </motion.div>
                        </div>
                        <div className="nidoeye">
                        <motion.div className="nidopupil"
                                initial={{
                                    y: 1
                                }}
                                animate={{
                                    x: [4, -4]
                                }}
                                transition={{ duration: 0.2, ease: "linear", repeat: Infinity, repeatType: "reverse", repeatDelay: 6.0, delay: 6.0 }}
                            >

                            </motion.div>
                            <motion.div className="nidoeyelid"
                                animate={{
                                    y: [75, 52, 75]
                                }}
                                transition={{ duration: 0.2, ease: "linear", repeat: Infinity, repeatDelay: 4.0, delay: 2.0 }}
                            >
                                
                            </motion.div>
                            <motion.div className="nidoeyelid"
                                
                                animate={{
                                    y: [-50, -40, -50]
                                }}
                                transition={{ duration: 0.2, ease: "linear", repeat: Infinity, repeatDelay: 4.0, delay: 2.0 }}
                            >
                                
                            </motion.div>
                        </div>
                    </div>
                    <div className="nidomouth"></div>
                </motion.div>
                <div className="nidoshdw">

                </div>
        </motion.div>
    )
}