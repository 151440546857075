import Header from "../../components/header";
import { useEffect } from "react";

export default function Terms() {

    useEffect(() => {
        document.title = 'Terms of Service - Cognido';
    }, []);

    return (
        <div>
            <Header background={"bdb"} btnShown={false} sticky={true} />
            <div className="sct fxc aic">
                <div className="c fxc pd6424 gp32">
                    <div className="fxc gp8">
                        <div className="fnts32 fntw700 fcb">
                        Terms of Service
                        </div>
                        <p className="fnts16 fntw400 fclg">
                            Last updated: 1.11.2024
                        </p>
                    </div>
                    <div className="fxc gp8">
                        <div className="fnts20 fntw600 fcb">
                        Account Terms
                        </div>
                        <p className="fnts16 fntw300 fcdg">
                        You must be at least 13 years old. You must be a human. Accounts registered by 'bots' or other automated methods are not permitted. You must provide a valid email address and any other information requested in order to complete the registration process. Your login may only be used by one person - i.e., a single login may not be shared by multiple people - except that a machine user's actions may be directed by multiple people. You are responsible for maintaining the security of your account and password. Cognido cannot and will not be liable for any loss or damage from your failure to comply with this security obligation. You are responsible for all activity that occurs under your account.
                        </p>
                    </div>
                    <div className="fxc gp8">
                        <div className="fnts20 fntw600 fcb">
                        Cancelation
                        </div>
                        <p className="fnts16 fntw300 fcdg">
                        You are solely responsible for properly canceling your account. You can cancel your account at any time by using the option in the Cognido iOS app. All of your content will be immediately deleted from the service upon cancelation. This information can not be recovered once your account is canceled. Cognido, in its sole discretion, has the right to suspend or terminate your account and refuse any and all current or future use of the Service, or any other Cognido service, for any reason at any time. Such termination of the Service will result in the deactivation or deletion of your Account or your access to your Account, and the forfeiture and relinquishment of all Content in your Account. Cognido reserves the right to refuse service to anyone for any reason at any time.
                        </p>
                    </div>
                    <div className="fxc gp8">
                        <div className="fnts20 fntw600 fcb">
                        Modifications
                        </div>
                        <p className="fnts16 fntw300 fcdg">
                        Cognido reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice. Cognido shall not be liable to you or to any third-party for any modification, suspension or discontinuance of the Service.
                        </p>
                    </div>
                    <div className="fxc gp8">
                        <div className="fnts20 fntw600 fcb">
                        General
                        </div>
                        <p className="fnts16 fntw300 fcdg">
                        Your use of the Service is at your sole risk. The service is provided on an 'as is' and 'as available' basis. Support for Cognido services is only available in English and German, via email. You understand that Cognido uses third-party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to run the service. We may, but have no obligation to, remove content and accounts containing content that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party's intellectual property or these Terms of Service. Verbal, physical, written or other abuse (including threats of abuse or retribution) of any Cognido member will result in immediate account termination. Cheating while playing any game in a match or not is not allowed and can lead to account termination.
                        </p>
                    </div>
                    <div className="fxc gp8">
                        <div className="fnts20 fntw600 fcb">
                        In-App Purchases
                        </div>
                        <p className="fnts16 fntw300 fcdg">
                        The Cognido iOS app now offers a monthly subscription option which will disable ads and allow access to all the apps features. Payments will be processed through Apple App Store and RevenueCat. You may access the applicable in-app purchase rules and policies directly from the app store or from the website of RevenueCat.
                        </p>
                    </div>
                    <div className="fxc gp8">
                        <div className="fnts20 fntw600 fcb">
                        Corrections
                        </div>
                        <p className="fnts16 fntw300 fcdg">
                        There may be information on the Site that contains typographical errors, inaccuracies, or omissions, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the Site at any time, without prior notice.
                        </p>
                    </div>
                    <div className="fxc gp8">
                        <div className="fnts20 fntw600 fcb">
                        How can I contact "Cognido"?
                        </div>
                        <p className="fnts16 fntw300 fcdg">
                        You can send questions, complaints, and requests regaring these Terms of Service to us at support@cognido.io.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}