import { motion } from "framer-motion"
import { Link } from "react-router-dom"

export default function Button(props) {

    const text = props.text

    const size = props.size

    const primary = props.primary
    const secondary = props.secondary

    const color = props.color

    function fnts() {
        if (size === "btns") {
            return "fnts15 "
        } else {
            return "fnts17 "
        }
    }

    return (
        <div className={size}
            
        >
            <motion.div className={size + "b " + secondary}
                initial={{
                    color: "#FFFFFF00"
                }}
            >btm</motion.div>
            <motion.div className={size + "t fxr jcsa aic " + fnts() + "fntw800 " + primary + " " + color}
                whileTap={{
                    y: 4
                }}
                onClick={() => props.onClick()}
            >
                {text}
            </motion.div>
        </div>
    )
}