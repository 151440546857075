export default function TextField(props) {

    const text = props.text;
    const setText = props.setText;

    return (
        <div className="txtf">
            <input type={props.secure ? "password" : "text"} value={text} onChange={(e) => setText(e.target.value)} />
            </div>
    );
}