import { useState } from "react";

import logo_w from "../assets/branding/logo.white.png"
import logotext_w from "../assets/branding/logotext.white.png"
import Button from "./button";

import { motion } from "framer-motion";

export default function Header(props) {

    const background = props.background
    const btnShown = props.btnShown
    const sticky = props.sticky

    const [bannerShown, setBannerShown] = useState(true);

    function fc() {
        if (background === "bdb") {
            return "fcdb"
        } else {
            return "fclb"
        }
    }

    return (
        <motion.div className={"sct hd fxc aic " + background + (sticky ? " sticky" : "")}
            initial={{
                y: sticky ? -60 : 0
            }}
            animate={{
                y: 0
            }}
            exit={{
                y: -60
            }}
            transition={{
                type: "linear",
                duration: 0.2
            }}
        >
            <div className={"hc fxr pd1224 aic " + (btnShown ? "jcsb" : "jcc")}>
                <a href="/">
                    <div className="fxr aic gp8">
                        <img src={logo_w} className="lghi"></img>
                        <img src={logotext_w} className="lght"></img>
                    </div>
                </a>
                <div className={btnShown ? "" : "none"}>
                    <Button text={"GET THE APP"} size={"btns"} primary={"bw"} secondary={background + "sec"} color={fc()} onClick={() => window.location.href = "#download"} />
                </div>
            </div>
        </motion.div>
    );
}