import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/header";

import icon from "../../assets/branding/cognido-icon.png"

import appstore from "../../assets/assets/appstorebtn.png"

export default function PlayDuel() {

    const queryParameters = new URLSearchParams(window.location.search);
    const uid = queryParameters.get("uid");

    const navigate = useNavigate();

    useEffect(() => {
        if (uid) {
            window.location.href = "cognido://play-duel?uid=" + uid
        }
        document.title = 'Invite Link - Cognido';
    }, []);

    return (
        <div>
            <Header background={"blb"} btnShown={false} sticky={true} />
            <div className="sct fxc aic">
                <div className="c pd9624 lnk fxc aic gp32">
                    <img src={icon} />
                    <div className="fnts24 fntw600 fcb">You got invited to play!</div>
                    <div>
                        <img src={appstore} onClick={() => {
                            window.open("https://apps.apple.com/de/app/cognido-minispiel-duelle/id6670284138");
                        }} />
                    </div>
                </div>
            </div>
        </div>
    )
}