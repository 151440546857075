import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

const config = {
    apiKey: "AIzaSyBufIlPeWvM85oDq-JpYshOQNhVZws7sBY",
    authDomain: "cognido-385d1.firebaseapp.com",
    projectId: "cognido-385d1",
    storageBucket: "cognido-385d1.appspot.com",
    messagingSenderId: "888466034777",
    appId: "1:888466034777:web:633f200af90d83d5006148",
    measurementId: "G-YZ57GFFT5E"
  };

  const firebase = initializeApp(config);
  const auth = getAuth(firebase)

  export { firebase, auth };